import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOWebpage from '../../components/SEO/webpage'
import { useStaticQuery, graphql } from "gatsby"

export default function ServiceArea() {

  const pageData = useStaticQuery(graphql`
    query ServiceArea {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      allGraphCmsServiceArea {
        group(field: stateLocation) {
          nodes {
            slug
            stateLocation
            locationName
          }
          fieldValue
          field
        }
      }
      graphCmsServiceAreaPage {
        id
        pageHeaderSeo {
          metaDescription
          metaTitle
        }
        slug
        content {
          html
        }
      }
    }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/" + pageData.graphCmsServiceAreaPage.slug + "/";
  const groupedStateLoc = pageData.allGraphCmsServiceArea.group;
  console.log(groupedStateLoc);


  return (
    <Layout>
      <Head
        title={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaTitle}
        pageDesc={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <SEOWebpage
        title={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaTitle}
        meta_description={pageData.graphCmsServiceAreaPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <div className="pageSection">
      {/* Ohio
Hamilton
Butler
Blue Ash
Bridgetown
Delhi
Dry Run
Forestville
Warren
Clermont
Indian Hill
Brown
Highland
Hyde Park
Clinton
Adams
Sixteen Mile Stand
Sharonville
White Oak
Kentucky
Kenton
Boone
Covington
Crescent Springs
Crestview Hills
Edgewood
Erlanger
Florence
Fort Mitchell
Fort Thomas
Fort Wright
Francisville
Campbell
Grant
Independence
Lakeside Park
Latonia
Newport
Park Hills
Pendleton
Owen
Carroll
Bracken
Gallatin
Robertson
Taylor Mill
Villa Hills
Union
Indiana
Ripley
Franklin
Switzerland
Union
Ohio
Dearborn */}
        <div className="content" dangerouslySetInnerHTML={{ __html: pageData.graphCmsServiceAreaPage.content.html }}>
        </div>
        {groupedStateLoc.map((state, index) =>
          <div>
            <h2>{state.fieldValue}</h2>
            {state.nodes.map((place) => <p><a href={place.slug + '/'}>{place.locationName}</a></p>)}
          </div>
        )}
      </div>
    </Layout>
  )
}
